var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_vm._m(0),_c('article',{staticClass:"container mx-auto p-4 md:p-32"},[_c('h2',{staticClass:"text-4xl md:text-6xl mb-16",staticStyle:{"color":"#f27405"}},[_vm._v(" Einbruchschutz für Ihre Immobilie in Berlin ")]),_vm._m(1),_vm._m(2),_c('h3',{staticClass:"uppercase text-2xl lg:pl-10 pb-3 flex flex-col lg-flex-row",staticStyle:{"color":"#033859"}},[_vm._v(" Darum sollten Sie sich für einen Einbruchschutz von BE-RO-MA in Berlin entscheiden: ")]),_vm._m(3),_c('p',{staticClass:"mb-24 px-5 text-gray-600"},[_vm._v(" Mit einem Einbruchschutz von BE-RO-MA brauchen Sie sich nicht mehr um Ihre Privatsphäre zu sorgen. Ein effektiver Einbruchschutz bewahrt Sie in Berlin nicht nur vor materiellen, sondern auch vor den psychischen und eventuell auch physischen Folgen eines Einbruchs. Gleichzeitig sorgt ein Einbruchschutz für ein größeres Sicherheitsgefühl im Alltag. Lassen Sie sich unter unserer Telefonnummer von unseren Experten in Berlin beraten!"),_c('br'),_c('br'),_c('contacticons')],1),_vm._m(4),_c('h3',{staticClass:"uppercase text-2xl lg:pl-10 pb-3",staticStyle:{"color":"#033859"}},[_vm._v(" Unser Schutz gegen Einbruch! ")]),_vm._m(5)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h2',{staticClass:"uppercase pl-4 md:pl-28 pt-4 text-gray-600"},[_vm._v(" Sie sind hier / "),_c('a',{staticStyle:{"color":"#f27405"},attrs:{"href":"/home"}},[_vm._v("Beroma Gmbh")]),_vm._v(" / Einbruchschutz "),_c('br')])
},function (){var _vm=this,_c=_vm._self._c;return _c('figure',{staticClass:"w-72 sm:w-full"},[_c('img',{attrs:{"src":require("../assets/siegelpngmittel-srcset-large.webp"),"alt":"rolladen siegel einbruchschutz"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"mb-24 px-5 text-gray-600"},[_vm._v(" Einbruchschutz - bedarfsgerechte Lösungen von BE-RO-MA in Berlin. Das Netzwerk „Zuhause sicher“ hat herausgefunden, dass 80 Prozent aller Einbrüche über das Aufhebeln des Fensters oder der Fenstertür erfolgen. Genau an dieser Stelle sollte ein wirksamer Einbruchschutz zum Tragen kommen. "),_c('br'),_c('br')])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"m-4 flex flex-col md:flex-row items-start lg:items-center"},[_c('li',{staticClass:"flex flex-row"},[_c('div',{staticClass:"mx-1",staticStyle:{"color":"#f27405"}},[_c('i',{staticClass:"fas fa-check"})]),_c('div',[_vm._v("Zeitgemäße sicherheitstechnische Lösungen")])]),_c('li',{staticClass:"flex flex-row"},[_c('div',{staticClass:"mx-1",staticStyle:{"color":"#f27405"}},[_c('i',{staticClass:"fas fa-check"})]),_c('div',[_vm._v("Optimale Wahrung der Privatsphäre")])]),_c('li',{staticClass:"flex flex-row"},[_c('div',{staticClass:"mx-1",staticStyle:{"color":"#f27405"}},[_c('i',{staticClass:"fas fa-check"})]),_c('div',[_vm._v("Sicherung materiellen Eigentums")])]),_c('li',{staticClass:"flex flex-row"},[_c('div',{staticClass:"mx-1",staticStyle:{"color":"#f27405"}},[_c('i',{staticClass:"fas fa-check"})]),_c('div',[_vm._v("Schutz für Körper und Geist")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex-col lg:flex-row mb-24"},[_c('figure',{staticClass:"w-full mb-5"},[_c('img',{staticClass:"w-96",attrs:{"src":require("../assets/herminghaus24.webp"),"alt":"herminghaus24 beroma"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"grid lg:grid-cols-2 gap-4 text-gray-600 p-4"},[_c('div',{staticClass:"flex flex-col lg:pl-14"},[_c('h4',{staticClass:"text-2xl",staticStyle:{"color":"#033859"}},[_vm._v(" Sicherheitsrolladen Heroal RC2 ")]),_c('figure',{staticClass:"w-72 sm:w-full py-4"},[_c('img',{attrs:{"src":require("../assets/img/heroal/rc2/rc2.webp"),"alt":"heroal rc2 sicherheitsrolladen"}})]),_c('button',{staticClass:"text-white rounded-sm w-1/3 mb-20 ring-4 ring-yellow-500 ring-opacity-50",staticStyle:{"background-color":"#f27405"}},[_c('a',{attrs:{"href":"/sicherheitsrollladen-RC2"}},[_vm._v("Erfahre Mehr")])])]),_c('div',{staticClass:"flex flex-col lg:pl-14"},[_c('div',{staticClass:"text-2xl",staticStyle:{"color":"#033859"}},[_vm._v(" Sicherheitsrolladen Heroal RC3 ")]),_c('div',{staticClass:"w-72 sm:w-full py-4"},[_c('img',{attrs:{"src":require("../assets/img/heroal/rc3/rc3.webp"),"alt":"heroal rc3 sicherheitsrolladen"}})]),_c('button',{staticClass:"text-white rounded-sm w-1/3 mb-20 ring-4 ring-yellow-500 ring-opacity-50",staticStyle:{"background-color":"#f27405"}},[_c('a',{attrs:{"href":"/sicherheitsrollladen-RC3"}},[_vm._v("Erfahre Mehr")])])])])
}]

export { render, staticRenderFns }