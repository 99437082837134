<template>
  <section>
    <h2 class="uppercase pl-4 md:pl-28 pt-4 text-gray-600">
      Sie sind hier / <a style="color: #f27405" href="/home">Beroma Gmbh</a> /
      Einbruchschutz <br />
    </h2>
    <article class="container mx-auto p-4 md:p-32">
      <h2 class="text-4xl md:text-6xl mb-16" style="color: #f27405">
        Einbruchschutz für Ihre Immobilie in Berlin
      </h2>
      <figure class="w-72 sm:w-full">
        <img
          src="../assets/siegelpngmittel-srcset-large.webp"
          alt="rolladen siegel einbruchschutz"
        />
      </figure>
      <p class="mb-24 px-5 text-gray-600">
        Einbruchschutz - bedarfsgerechte Lösungen von BE-RO-MA in Berlin. Das
        Netzwerk „Zuhause sicher“ hat herausgefunden, dass 80 Prozent aller
        Einbrüche über das Aufhebeln des Fensters oder der Fenstertür erfolgen.
        Genau an dieser Stelle sollte ein wirksamer Einbruchschutz zum Tragen
        kommen. <br /><br />
      </p>
      <h3
        class="uppercase text-2xl lg:pl-10 pb-3 flex flex-col lg-flex-row"
        style="color: #033859"
      >
        Darum sollten Sie sich für einen Einbruchschutz von BE-RO-MA in Berlin
        entscheiden:
      </h3>
      <ul class="m-4 flex flex-col md:flex-row items-start lg:items-center">
        <li class="flex flex-row">
          <div class="mx-1" style="color: #f27405">
            <i class="fas fa-check"></i>
          </div>
          <div>Zeitgemäße sicherheitstechnische Lösungen</div>
        </li>
        <li class="flex flex-row">
          <div class="mx-1" style="color: #f27405">
            <i class="fas fa-check"></i>
          </div>
          <div>Optimale Wahrung der Privatsphäre</div>
        </li>
        <li class="flex flex-row">
          <div class="mx-1" style="color: #f27405">
            <i class="fas fa-check"></i>
          </div>
          <div>Sicherung materiellen Eigentums</div>
        </li>
        <li class="flex flex-row">
          <div class="mx-1" style="color: #f27405">
            <i class="fas fa-check"></i>
          </div>
          <div>Schutz für Körper und Geist</div>
        </li>
      </ul>
      <p class="mb-24 px-5 text-gray-600">
        Mit einem Einbruchschutz von BE-RO-MA brauchen Sie sich nicht mehr um
        Ihre Privatsphäre zu sorgen. Ein effektiver Einbruchschutz bewahrt Sie
        in Berlin nicht nur vor materiellen, sondern auch vor den psychischen
        und eventuell auch physischen Folgen eines Einbruchs. Gleichzeitig sorgt
        ein Einbruchschutz für ein größeres Sicherheitsgefühl im Alltag. Lassen
        Sie sich unter unserer Telefonnummer von unseren Experten in Berlin
        beraten!<br /><br />
        <contacticons></contacticons>
      </p>

      <div class="flex flex-col lg:flex-row mb-24">
        <figure class="w-full mb-5">
          <img
            class="w-96"
            src="../assets/herminghaus24.webp"
            alt="herminghaus24 beroma"
          />
        </figure>
      </div>
      <h3 class="uppercase text-2xl lg:pl-10 pb-3" style="color: #033859">
        Unser Schutz gegen Einbruch!
      </h3>
      <div class="grid lg:grid-cols-2 gap-4 text-gray-600 p-4">
        <div class="flex flex-col lg:pl-14">
          <h4 class="text-2xl" style="color: #033859">
            Sicherheitsrolladen Heroal RC2
          </h4>
          <figure class="w-72 sm:w-full py-4">
            <img
              src="../assets/img/heroal/rc2/rc2.webp"
              alt="heroal rc2 sicherheitsrolladen"
            />
          </figure>
          <button
            class="text-white rounded-sm w-1/3 mb-20 ring-4 ring-yellow-500 ring-opacity-50"
            style="background-color: #f27405"
          >
            <a href="/sicherheitsrollladen-RC2">Erfahre Mehr</a>
          </button>
        </div>
        <div class="flex flex-col lg:pl-14">
          <div class="text-2xl" style="color: #033859">
            Sicherheitsrolladen Heroal RC3
          </div>
          <div class="w-72 sm:w-full py-4">
            <img
              src="../assets/img/heroal/rc3/rc3.webp"
              alt="heroal rc3 sicherheitsrolladen"
            />
          </div>
          <button
            class="text-white rounded-sm w-1/3 mb-20 ring-4 ring-yellow-500 ring-opacity-50"
            style="background-color: #f27405"
          >
            <a href="/sicherheitsrollladen-RC3">Erfahre Mehr</a>
          </button>
        </div>
      </div>
    </article>
  </section>
</template>
<script>
import contacticons from "../components/contact";
export default {
  name: "App",
  components: {
    contacticons,
  },
};
</script>
